import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import {graphql, StaticQuery} from 'gatsby';

import BrandControls from 'components/BrandControls.js';
import Menu from 'components/Menu.js';
import 'assets/screen.less';

const BrandContext = React.createContext();


export default class Layout extends React.Component {
    static propTypes = {
        brands: PropTypes.array.isRequired,
        children: PropTypes.node.isRequired,
        frontmatter: PropTypes.object.isRequired,
    };

    static defaultProps = {
        brands: ['HealthShare', 'BetterConsult', 'ConnectMe', 'SpecialistNow'],
    };

    constructor(props) {
        super(props);
        this.state = {
            brand: this.props.brands[0],
        };
        this.handleBrandChange = this.handleBrandChange.bind(this);
    }

    handleBrandChange(brand) {
        this.setState({brand});
    }

    render() {
        return (
            <StaticQuery
                query={graphql`
                    query {
                        site {
                            siteMetadata {
                                title
                                siteUrl
                            }
                        }
                    }
                `}
                render={(data) => {
                    const frontmatter = this.props.frontmatter;
                    const metadata = data.site.siteMetadata;
                    return (
                        <BrandContext.Provider value={this.state}>
                            <Helmet>
                                <title>
                                    {frontmatter.title} | {metadata.title}
                                </title>
                                <link href={metadata.siteUrl} rel="canonical"/>
                            </Helmet>
                            <Menu/>
                            <main data-brand={this.state.brand} role="main">
                                <BrandControls
                                    brands={this.props.brands}
                                    currentBrand={this.state.brand}
                                    handleBrandChange={this.handleBrandChange}
                                />
                                <article>
                                    {this.props.children}
                                </article>
                            </main>
                        </BrandContext.Provider>
                    );
                }}
            />
        );
    }
}

export const BrandConsumer = BrandContext.Consumer;
