import React from 'react';
import PropTypes from 'prop-types';

import BrandControl from 'components/BrandControl.js';


export default class BrandControls extends React.Component {
    static propTypes = {
        brands: PropTypes.array.isRequired,
        currentBrand: PropTypes.string.isRequired,
        handleBrandChange: PropTypes.func.isRequired,
    };

    render() {
        return (
            <nav className="brand-modifier">
                {this.props.brands.map((brand, index) => (
                    <BrandControl
                        current={this.props.currentBrand === brand}
                        handleBrandChange={
                            this.props.handleBrandChange.bind(null, brand)
                        }
                        key={index}
                        name={brand}
                    />
                ))}
            </nav>
        );
    }
}
