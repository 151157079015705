import React from 'react';
import {graphql, Link, StaticQuery} from 'gatsby';

import MenuCategory from 'components/MenuCategory.js';


export default class Menu extends React.Component {
    render() {
        return (
            <StaticQuery
                query={graphql`
                    query IndexPageQuery {
                        allMarkdownRemark(
                            sort: {fields: [frontmatter___title], order: ASC},
                        ) {
                            edges {
                                node {
                                    id
                                    frontmatter {
                                        category
                                        path
                                        status
                                        title
                                    }
                                }
                            }
                        }
                    }
                `}
                render={(data) => (
                    <aside className="menu">
                        <header>
                            <Link to="/">
                                <strong>{'HealthShare'}</strong>
                                {'Design System'}
                            </Link>
                        </header>
                        <MenuCategory
                            data={data.allMarkdownRemark.edges}
                            name="Branding"
                        />
                        <MenuCategory
                            data={data.allMarkdownRemark.edges}
                            name="Assets"
                        />
                        <MenuCategory
                            data={data.allMarkdownRemark.edges}
                            name="Process"
                        />
                    </aside>
                )}
            />
        );
    }
}
