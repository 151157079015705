import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Layout from 'components/Layout.js';


export default class Guide extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        pageContext: PropTypes.object.isRequired,
    };

    render() {
        const frontmatter = this.props.pageContext.frontmatter;
        return (
            <Layout frontmatter={frontmatter}>
                <h1>{frontmatter.title}</h1>
                <time>
                    {`Updated ${
                        moment(frontmatter.date).format('D MMM YYYY')
                    }`}
                </time>
                {this.props.children}
            </Layout>
        );
    }
}
