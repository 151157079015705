import React from 'react';
import {Link} from 'gatsby';
import PropTypes from 'prop-types';


export default class MenuCategory extends React.Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        name: PropTypes.string.isRequired,
    };

    render() {
        const slug = this.props.name.toLowerCase();
        return (
            <React.Fragment>
                <h2>{this.props.name}</h2>
                {this.props.data
                    .filter(({node}) => (
                        node.frontmatter.category === slug
                    ))
                    .map(({node}) => (
                        <Link
                            className={node.frontmatter.status}
                            key={node.id}
                            to={node.frontmatter.path}
                        >
                            {node.frontmatter.title}
                        </Link>
                    ))
                }
            </React.Fragment>
        );
    }
}
