import React from 'react';
import PropTypes from 'prop-types';

import sprite from 'assets/sprite.js';


export default class Graphic extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
    };

    getGraphicFromSprite() {
        try {
            return sprite[this.props.name];
        } catch(exception) {
            // eslint-disable-next-line no-console
            console.warn(`Missing graphic for "${this.props.name}"`);
            return false;
        }
    }

    render() {
        return (this.getGraphicFromSprite());
    }
}
