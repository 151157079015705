import React from 'react';
import PropTypes from 'prop-types';

import Graphic from 'components/Graphic.js';


export default class BrandControl extends React.Component {
    static propTypes = {
        current: PropTypes.bool,
        handleBrandChange: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
    };

    render() {
        return (
            <button
                aria-current={this.props.current}
                onClick={this.props.handleBrandChange}
                value={this.props.name}
            >
                <Graphic name={`brand${this.props.name}`}/>
                {this.props.name}
            </button>
        );
    }
}
